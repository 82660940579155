<template>
<v-container fluid class="goal-container pa-0">
  <v-row style="height:100%;width:100%;" no-gutters>
    <SubMenu v-if="showSubMenu" @changePage="handle_changePage"/>
    <v-col>
      <!--<Header /> -->
      <router-view :pageMode="pageMode" @selectKR="selectKR" @selectedObject="selectedObject" @ShowSubMenu="handle_ShowSubMenu"></router-view>
    </v-col>
    <RightEditor ref="rightEditor" />
  </v-row>
</v-container>
</template>

<script>
//import RightSidebar from '@/components/common/RightSidebar.vue';
//import OKRGoalListView from '@/components/okrgoal/OKRGoalListView.vue';
//import SubMenu from '@/components/okrgoal/SubMenu.vue';


export default {
  components: {
    //RightSidebar,
    //OKRGoalListView,
    //SubMenu,

    RightSidebar: () => import("@/components/common/RightSidebar.vue"),
    OKRGoalListView: () => import("@/components/okrgoal/OKRGoalListView.vue"),
    SubMenu: () => import("@/components/okrgoal/SubMenu.vue"),

    // Header: () => import("@/components/okrgoal/Header.vue"),
    RightEditor: () => import('@/components/okrgoal/rightInfo/RightEditor.vue'),
    // GoalLeftbar: () => import('@/components/okrgoal/GoalLeftbar.vue')


  },
  data() {
    return {
      app: true,
      isEditing: false,
      header: true,
      showSubMenu: null,
			pageMode: false,
    }
  },
  created() {
    this.showSubMenu = true;
  },
  methods: {
		handle_changePage(chk){
			this.pageMode = chk;
		},
    handdle_appToggle(prop) {
      this.app = prop;
    },
    selectKR(data) {
      this.$refs.rightEditor.open(data);
      this.isEditing = true
    },
    selectedObject() {
      this.$refs.rightEditor?.close()
      this.isEditing = false
      this.header = false
      this.showSubMenu = !this.showSubMenu;
    },
    handleClose() {
      if(this.isEditing){
        this.$refs.rightEditor?.close()
        this.isEditing = false
      }
    },
    handle_ShowSubMenu(prop) {
      this.showSubMenu = prop;
    }
  }
}
</script>

<style>
.goal-container {
  height: 100%;
  width: 100%;
}

.v-main__wrap {
  height: 100%;
  width: 100%;
}
</style>
